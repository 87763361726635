// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/dealDetail/DealDetailPreview.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/dealDetail/DealDetailPreview.tsx");
}
// REMIX HMR END

import { Box, Button, Center, Collapse, Grid, GridItem, HStack, Heading, Image, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, Spacer, Tag, Text, Wrap, WrapItem, useColorModeValue } from "@chakra-ui/react";
import { BlogAuthor, BlogAuthorBig, DealButton, DealButtonGroup, DealDate, DealHotnessButton } from "../dealList";
import { BsBookmarkPlus, BsChatText, BsShare } from "react-icons/bs";
import { DealPriceBig } from "../dealList/DealPrice";
import { Link } from "@remix-run/react";
import { isEditor, useAuth } from "~/utils/userUtils";
import { forwardRef, useEffect, useState } from "react";
export const DealDetailPreview = _s(forwardRef(_c = _s((props, ref) => {
  _s();
  const {
    deal
  } = props;
  const {
    user,
    login,
    logout
  } = useAuth();
  const [editor, setEditor] = useState(false);
  const color = useColorModeValue("white", "gray.800");
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  useEffect(() => {
    setEditor(isEditor(user));
  }, [user]);
  return <Grid templateAreas={`"image"
        "title"
        "details"
        "content"`} gridTemplateRows={{
    base: "auto"
  }} gridTemplateColumns={{
    base: "auto"
  }} gap="5">

      <GridItem pl={{
      base: "0"
    }} area={"image"}>
        <Center>
          <Image loading="lazy" padding={{
          base: 1
        }} rounded={"lg"} height={{
          base: "300px"
        }} width={{
          base: "300px"
        }} objectFit={"contain"} src={deal.titleImage || "/assets/placeholder-image.jpg"} alt={deal.title} bgColor={"white"} fallbackSrc="/assets/placeholder-image.jpg" />

        </Center>
      </GridItem>
      <GridItem pl={{
      base: 0
    }} area={"title"} pt={{
      base: "1"
    }}>
        <HStack display={{
        base: "none"
      }} pb="5">
          <DealHotnessButton id={deal.id} hotness={deal.hotness} />
          <Spacer />
          <DealDate date={deal.dealDate} />
        </HStack>
        <Heading fontSize={{
        base: "xl"
      }}>{deal.title}</Heading>
      </GridItem>
      <GridItem pl={{
      base: 0
    }} area={"details"}>
        <Box pb={3}>
          <DealPriceBig price={deal.price} originalPrice={deal.originalPrice} discount={deal.discount} />

        </Box>
        <Box pb={5}>
          {deal.voucherCode && deal.id && <DealButtonGroup affiliateLink={deal.affiliateLink} id={deal.id} voucherCode={deal.voucherCode} />}


          {!deal.voucherCode && <DealButton affiliateLink={deal.affiliateLink} id={deal.id} />}

        </Box>
        <HStack>
          <Popover isLazy={true}>
            <PopoverTrigger>
              <Button variant="link">
                <BlogAuthor name={deal.editor_username} imageURL={deal.editor_profilePictureThumb || undefined} />

              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <BlogAuthorBig name={deal.edito_username} imageURL={deal.editor_profilePicture || undefined} />

              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Spacer />
          <Button colorScheme="gray" color={useColorModeValue("gray.600", "gray.100")} size={{
          base: "sm"
        }}>

            <BsShare />
          </Button>
          <Button colorScheme="gray" color={useColorModeValue("gray.600", "gray.100")} size={{
          base: "sm"
        }}>

            <BsBookmarkPlus />
          </Button>
          <Button leftIcon={<BsChatText />} color={useColorModeValue("gray.600", "gray.100")} size={{
          base: "sm"
        }}>

            0
          </Button>
        </HStack>
      </GridItem>
      <GridItem
    // pl={{ base: "0", md: "2" }}
    area={"content"} pt={{
      base: 1
    }}>

        {deal?.content && <>
            <Collapse startingHeight={"12em"} in={show}>
              <Box fontSize={"md"}
          // px={{ base: "0", md: "100px" }}
          maxW={"700px"} pt={{
            base: 1
          }} mx={"auto"} fontWeight={"medium"} className="deal-content" overflow={"hidden"} w={"100%"} dangerouslySetInnerHTML={{
            __html: deal?.content ? deal.content.replace(/\s?srcset="[^"]*"/gi, "") : ""
          }} />

            </Collapse>
            <Button size="sm" onClick={handleToggle} mt="1rem" mb="5">
              {show ? "Weniger" : "Mehr"}  anzeigen
            </Button>
          </>}


        <Wrap maxW={"700px"} mx={"auto"}>
          {deal.categories?.length && <>
              <WrapItem>
                <Center>
                  <Text fontSize={"smaller"} fontWeight="bold" color={useColorModeValue("gray.500", "gray.100")}
              // pr="1"
              >
                    Kategorie:
                  </Text>
                </Center>
              </WrapItem>
              {deal.categories.map((item, index) => <WrapItem key={index}>
                  <Center>
                    {item.url && <Link to={`/${item.url}/1`}>
                      <Tag size={"sm"} fontSize={"smaller"}>
                        {item.title}
                      </Tag>
                    </Link>}
                    {!item.url && <Tag size={"sm"} fontSize={"smaller"}>
                        {item.title}
                      </Tag>}
                  </Center>
                </WrapItem>)}
            </>}

        </Wrap>
      </GridItem>
    </Grid>;
}, "Izkh+bj99eKcwN6uA/3O4HnllCk=", false, function () {
  return [useAuth, useColorModeValue, useColorModeValue, useColorModeValue, useColorModeValue, useColorModeValue];
})), "Izkh+bj99eKcwN6uA/3O4HnllCk=", false, function () {
  return [useAuth, useColorModeValue, useColorModeValue, useColorModeValue, useColorModeValue, useColorModeValue];
});
_c2 = DealDetailPreview;
var _c, _c2;
$RefreshReg$(_c, "DealDetailPreview$forwardRef");
$RefreshReg$(_c2, "DealDetailPreview");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;