// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/dealList/DealButton.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$(),
  _s3 = $RefreshSig$(),
  _s4 = $RefreshSig$(),
  _s5 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/dealList/DealButton.tsx");
}
// REMIX HMR END

import { AddIcon, CopyIcon, ExternalLinkIcon, MinusIcon } from "@chakra-ui/icons";
import { Box, Button, Grid, GridItem, HStack, IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Text, Tooltip, Wrap, WrapItem, useClipboard, useColorModeValue, useDisclosure, useToast } from "@chakra-ui/react";
import { useFetcher } from "@remix-run/react";
import moment from "moment-timezone";
import { forwardRef, useEffect, useState } from "react";
import { BsBookmarkPlus, BsShare } from "react-icons/bs";
moment.tz.setDefault("Europe/Berlin");
moment.locale("de");
import animationStyles from "~/styles/animation.css";
import { useAuth } from "~/utils/userUtils";
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, ViberIcon, ViberShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { sendEvent } from "~/utils/fetchUtils";
import { CloakingLink, createShortLink, voucherUtil } from "~/utils/affiliUtils";
export const dealButtonLinks = () => [{
  rel: "stylesheet",
  href: animationStyles
}];
export const DealButtonGroup = props => {
  _s();
  const {
    dealButton
  } = props;
  const {
    onCopy,
    value,
    setValue,
    hasCopied
  } = useClipboard("");
  const {
    user
  } = useAuth();
  const fetcher = useFetcher();
  const [voucher, setVoucher] = useState(props.voucherCode);
  const [voucherText, setVoucherText] = useState(props.voucherCode);
  const [code, setCode] = useState();
  const [loading, setLoading] = useState(false);

  // voucherUtil
  function fetchData() {
    sendEvent({
      event: "hotness",
      id: String(props.id),
      tag: "click"
    });
  }
  useEffect(() => {
    if (value !== "") {
      onCopy();
    }
  }, [value]);
  const [clickedButtonId, setClickedButtonId] = useState(null);
  const handleCopyClick = buttonId => {
    // Hier können Sie Ihren Kopiervorgang implementieren, z. B. mit dem Clipboard API
    setClickedButtonId(buttonId);
    setTimeout(() => setClickedButtonId(null), 2000); // Nachricht nach 2 Sekunden ausblenden
  };

  function findHashEnclosedStrings(text) {
    const regex = /#.*?#/g;
    const matches = text.match(regex) || [];
    return matches;
  }
  useEffect(() => {
    const match = props.voucherCode && findHashEnclosedStrings(props.voucherCode) || [];
    if (match.length > 0) {
      setVoucherText("Code erstellen");
      const code = match[0].replaceAll("#", "");
      setCode(code);
    }
  }, [props.voucherCode]);
  return <>
      <Grid templateColumns={{
      base: dealButton ? "repeat(1, 2fr)" : "repeat(1, 2fr)",
      md: "repeat(2, 1fr)"
    }} gap={{
      base: "3",
      md: "4",
      lg: "6"
    }}>

        <GridItem w="100%">
          {dealButton ? dealButton : <CloakingLink href={props.affiliateLink} target="_blank" type="deal" field="affiliateLink" entryId={props.id} children={<Button w="100%" h="10" bg={"linear-gradient(135deg, #f72461, #c914ba)"} color={useColorModeValue("gray.100", "gray.200")} _hover={{
          bgColor: useColorModeValue("pink.100", "pink.100"),
          color: useColorModeValue("gray.50", "gray.50")
        }} borderRadius="3xl" rightIcon={<ExternalLinkIcon />} onClick={() => fetchData()}>

                  Zum Deal
                </Button>} />}



        </GridItem>
        <GridItem w="100%">
          <Button w="100%" h="10" alignItems={"center"}
        // bgGradient="linear(to-t, pink.300, pink.500)"
        color={useColorModeValue("gray.700", "gray.100")} borderRadius="3xl" border={2} borderStyle={"dashed"} borderColor={"pink.500"} rightIcon={code ? <></> : <CopyIcon />} className="copy-container" id={`voucher-${props.id}`} loadingText="Code wird erstellt.." isLoading={loading} onClick={() => {
          if (code) {
            setLoading(true);
            voucherUtil(code).then(result => {
              if (result.code) {
                setCode(undefined);
                setVoucher(result.code);
                setVoucherText(result.code);
              }
              setLoading(false);
            }).catch(e => {
              setLoading(false);
              setVoucherText("Error :(");
            });
          } else {
            setValue(voucher ? voucher : "");
            handleCopyClick(`voucher-${props.id}`);
          }
        }} fontSize={{
          base: "smaller",
          md: "smaller",
          lg: "md"
        }} display={"flex"}>

            <Text isTruncated={true} maxW={{
            base: "160px",
            md: "70px",
            lg: "250px"
          }}>

              {voucherText}
            </Text>
            {clickedButtonId === `voucher-${props.id}` && <span className="copied-message">Kopiert!</span>}

          </Button>
        </GridItem>
      </Grid>
    </>;
};
_s(DealButtonGroup, "EZefL/T/U0O2QK+hbrdxgTtw6bw=", false, function () {
  return [useClipboard, useAuth, useFetcher, useColorModeValue, useColorModeValue, useColorModeValue, useColorModeValue];
});
_c = DealButtonGroup;
export const DealButtonInner = _s2(forwardRef(_c2 = _s2((props, ref) => {
  _s2();
  return <Button ref={ref} bg={"linear-gradient(45deg, #f72461, #c914ba)"} color={useColorModeValue("gray.100", "gray.200")} _hover={{
    bgColor: useColorModeValue("pink.100", "pink.100"),
    color: useColorModeValue("gray.50", "gray.50")
  }} borderRadius="3xl" rightIcon={<ExternalLinkIcon />} {...props}>

        Zum Deal
      </Button>;
}, "6SPWDKKJx3x6/piDRqtxmfjVGnY=", false, function () {
  return [useColorModeValue, useColorModeValue, useColorModeValue];
})), "6SPWDKKJx3x6/piDRqtxmfjVGnY=", false, function () {
  return [useColorModeValue, useColorModeValue, useColorModeValue];
});
_c3 = DealButtonInner;
export const DealButton = props => {
  function fetchData() {
    props.id && sendEvent({
      event: "hotness",
      id: String(props.id),
      tag: "click"
    });
  }
  return <>
      <CloakingLink href={props?.affiliateLink ? props.affiliateLink : ""} target="_blank" type="deal" field="affiliateLink" entryId={props.id} children={<DealButtonInner deal_id={props.id} onClick={() => fetchData()} />} />


    </>;
};
_c4 = DealButton;
export const DealHotnessButton = props => {
  _s3();
  const {
    size,
    hotness = 0,
    id
  } = props;
  const {
    user
  } = useAuth();
  const fetcher = useFetcher();
  const [hotnessVal, setHotnessVal] = useState(hotness);
  function fetchData(val) {
    id && sendEvent({
      event: "hotness",
      id: String(id),
      value: val,
      tag: "hotness"
    }).then(result => {
      setHotnessVal(hotness + val);
    });
  }
  return <HStack gap={0}>
      <Button borderTopLeftRadius={"full"} borderBottomLeftRadius={"full"} bgColor={useColorModeValue("gray.100", "gray.700")} variant="ghost" color={"blue"} as={MinusIcon} p={size == "sm" ? 2 : 3} size={size} fontSize={size} lineHeight={size == "sm" ? 8 : 10} onClick={() => fetchData(-5)} />

      <Box bgColor={useColorModeValue("gray.100", "gray.700")} h={"100%"} px={1} lineHeight={size == "sm" ? 8 : 10}>

        <Tooltip label="Gesamthotness: Kombination aus Klicks, Ansichten und Votes" aria-label="Hotness">

          <Text fontWeight={"semibold"} color={"red"} fontSize={size}>
            {hotnessVal}°
          </Text>
        </Tooltip>
      </Box>
      <Button borderTopRightRadius={"full"} borderBottomRightRadius={"full"} bgColor={useColorModeValue("gray.100", "gray.700")} variant="ghost" color={"red"} as={AddIcon} p={size == "sm" ? 2 : 3} size={size} fontSize={size} onClick={() => fetchData(5)} />

    </HStack>;
};
_s3(DealHotnessButton, "GJdfKFcN35n6h4eON313vJLdR+w=", false, function () {
  return [useAuth, useFetcher, useColorModeValue, useColorModeValue, useColorModeValue];
});
_c5 = DealHotnessButton;
export const DealBookmarkButton = props => {
  _s4();
  const {
    id,
    deal
  } = props;
  const {
    user,
    login,
    checkUsershowLogin
  } = useAuth();
  const fetcher = useFetcher();
  const fetcherBookmark = useFetcher();
  const fetcherUser = useFetcher();
  const [bookmark, setBookmark] = useState(false);
  const [loading, setLoading] = useState(false);
  function fetchData(book) {
    if (loading) {
      return;
    }
    setLoading(true);
    user && id && sendEvent({
      event: "hotness",
      id: String(id),
      tag: "bookmark",
      value: book ? 0 : 1
    });
    let bookmarks = [...user?.bookmarks] || [id];
    if (user?.bookmarks) {
      bookmarks = user.bookmarks.filter(val => val.id != id).map(val => val.id);
      if (book) {
        bookmarks = [...user.bookmarks.map(val => val.id), id];
      }
    }
    user && fetcherBookmark.submit({
      bookmarks
    }, {
      method: "post",
      action: "/api/strapi/update/users/" + user.id,
      encType: "application/json"
    });
  }
  useEffect(() => {
    if (user && user.bookmarks && deal && fetcherBookmark.data && loading) {
      setBookmark(!bookmark);
      setLoading(false);
      fetcherUser.load("/user");
    }
  }, [fetcherBookmark.data]);
  useEffect(() => {
    if (fetcherUser.data) {
      // console.log(fetcherUser.data)
      const fetchedUser = fetcherUser.data;
      login(fetchedUser);
    }
  }, [fetcherUser.data]);
  const [fetchAfterLogin, setFetchAfterLogin] = useState(false);
  useEffect(() => {
    if (user?.bookmarks) {
      const bookmarkFound = user.bookmarks.filter(val => val.id == id);
      setBookmark(bookmarkFound.length > 0);
    }
    if (fetchAfterLogin) {
      setFetchAfterLogin(false);
      fetchData(!bookmark);
    }
  }, [user]);
  return <Button colorScheme={bookmark ? "pink" : "gray"} color={bookmark ? "gray.100" : useColorModeValue("gray.600", "gray.100")} size={{
    base: "sm",
    md: "sm",
    lg: "md"
  }} onClick={() => {
    if (checkUsershowLogin()) {
      fetchData(!bookmark);
    } else {
      setFetchAfterLogin(true);
    }
  }} disabled={loading} isLoading={loading} aria-label={"Bookmark"}>

      <BsBookmarkPlus />
    </Button>;
};
_s4(DealBookmarkButton, "bgNOn9xnv1wn8HECW5kkW7FcqAs=", false, function () {
  return [useAuth, useFetcher, useFetcher, useFetcher, useColorModeValue];
});
_c6 = DealBookmarkButton;
export const DealShareButton = props => {
  _s5();
  const {
    isOpen,
    onClose,
    onOpen
  } = useDisclosure();
  const {
    link,
    text,
    dealid
  } = props;
  const fetcher = useFetcher();
  const {
    user
  } = useAuth();
  const {
    onCopy,
    value,
    setValue,
    hasCopied
  } = useClipboard("");
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (hasCopied && value) {
      toast({
        title: `Kopiert!`,
        variant: "left-accent",
        description: "Link: " + value,
        isClosable: true,
        position: "bottom",
        status: "info"
      });
      setLoading(false);
    }
  }, [hasCopied]);
  useEffect(() => {
    if (isOpen) {
      createShortLink(link, "deal", dealid).then(shortUrl => {
        setValue(shortUrl);
      });
    }
  }, [isOpen]);
  return <>
      <Popover onOpen={onOpen} isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>
          <Button {...props} color={useColorModeValue("gray.600", "gray.100")}>
            <BsShare />
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Deal teilen</PopoverHeader>
          <PopoverBody>
            <Wrap>
              <WrapItem>
                <WhatsappShareButton url={link}>
                  <WhatsappIcon round={true} size={"40px"} />
                </WhatsappShareButton>
              </WrapItem>
              <WrapItem>
                <FacebookShareButton url={link}>
                  <FacebookIcon round={true} size={"40px"} />
                </FacebookShareButton>
              </WrapItem>
              <WrapItem>
                <TwitterShareButton url={link}>
                  <TwitterIcon round={true} size={"40px"} />
                </TwitterShareButton>
              </WrapItem>
              <WrapItem>
                <EmailShareButton url={link}>
                  <EmailIcon round={true} size={"40px"} />
                </EmailShareButton>
              </WrapItem>

              <WrapItem>
                <LinkedinShareButton url={link}>
                  <LinkedinIcon round={true} size={"40px"} />
                </LinkedinShareButton>
              </WrapItem>
              <WrapItem>
                <TelegramShareButton url={link}>
                  <TelegramIcon round={true} size={"40px"} />
                </TelegramShareButton>
              </WrapItem>
              <WrapItem>
                <IconButton icon={<CopyIcon />} borderRadius={"full"} boxSize={"40px"} onClick={() => {
                setLoading(true);
                onCopy();
              }} aria-label="Copy" colorScheme="blue" variant={"solid"} isLoading={loading} />

              </WrapItem>
              <WrapItem>
                <ViberShareButton url={link}>
                  <ViberIcon round={true} size={"40px"} />
                </ViberShareButton>
              </WrapItem>
            </Wrap>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>;
};
_s5(DealShareButton, "CC3Po5BB/dQcchxIYQxK8Rzoyoo=", false, function () {
  return [useDisclosure, useFetcher, useAuth, useClipboard, useToast, useColorModeValue];
});
_c7 = DealShareButton;
var _c, _c2, _c3, _c4, _c5, _c6, _c7;
$RefreshReg$(_c, "DealButtonGroup");
$RefreshReg$(_c2, "DealButtonInner$forwardRef");
$RefreshReg$(_c3, "DealButtonInner");
$RefreshReg$(_c4, "DealButton");
$RefreshReg$(_c5, "DealHotnessButton");
$RefreshReg$(_c6, "DealBookmarkButton");
$RefreshReg$(_c7, "DealShareButton");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;