// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/dealList/DealPrice.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/dealList/DealPrice.tsx");
  import.meta.hot.lastModified = "1709112347287.6187";
}
// REMIX HMR END

import { HStack, VStack } from "@chakra-ui/react";
import { GermanNumberText } from "~/utils/MeiliSearch";
export const DealPrice = props => {
  // console.log(props);
  let discount = 0;
  if (props.price && !!props.originalPrice) {
    discount = (1 - props.price / props.originalPrice) * 100;
  }
  return <>
      <HStack>
        {props.price && <GermanNumberText fontSize={{
        base: "xl",
        md: "2xl"
      }} value={props.price} content="" textColor={"green.500"} fontWeight={"bold"} decimal={2} options={{
        style: 'currency',
        currency: 'EUR'
      }} />}
        {props.originalPrice && <GermanNumberText fontSize={{
        base: "md",
        md: "lg"
      }} value={props.originalPrice} content="" fontWeight={"semibold"} textColor={"gray"} textDecoration={"line-through"} decimal={2} options={{
        style: 'currency',
        currency: 'EUR'
      }} />}
        {(props?.discount && props.discount > 0 || discount > 0) && <GermanNumberText fontSize={{
        base: "md",
        md: "lg"
      }} value={props.discount ? props.discount : discount} content="%" textColor={"grey.400"} fontWeight={"normal"} decimal={0} contentLeft="-" />}
      </HStack>
    </>;
};
_c = DealPrice;
export const DealPriceBig = props => {
  let discount = 0;
  if (props.price && !!props.originalPrice) {
    discount = (1 - props.price / props.originalPrice) * 100;
  }
  return <>
      <HStack align={"baseline"}>
        {props.price && <GermanNumberText fontSize={{
        base: "3xl",
        md: "4xl"
      }} value={props.price} content="" textColor={"green.400"} fontWeight={"bold"} decimal={2} bgImage={"linear-gradient(45deg, #24bcf7, #73c914);"} bgClip={"text"} options={{
        style: 'currency',
        currency: 'EUR'
      }} />}
        {props.originalPrice && <GermanNumberText fontSize={{
        base: "lg",
        md: "xl"
      }} value={props.originalPrice} content="" fontWeight={"semibold"} textColor={"gray"} textDecoration={"line-through"} decimal={2} options={{
        style: 'currency',
        currency: 'EUR'
      }} />}
        {(props?.discount && props.discount > 0 || discount > 0) && <GermanNumberText fontSize={{
        base: "lg",
        md: "xl"
      }} value={props.discount ? props.discount : discount} content="%" textColor={"grey.400"} fontWeight={"normal"} decimal={0} options={{
        style: 'decimal'
      }} contentLeft="-" />}
      </HStack>
    </>;
};
_c2 = DealPriceBig;
export const DealPriceBigCenter = props => {
  let discount = 0;
  if (props.price && !!props.originalPrice) {
    discount = (1 - props.price / props.originalPrice) * 100;
  }
  return <>
      <VStack gap={0}>
        {props.originalPrice && <GermanNumberText fontSize={{
        base: "md",
        md: "xl"
      }} lineHeight={"2"} value={props.originalPrice} content="" fontWeight={"semibold"} textColor={"gray"} options={{
        style: 'currency',
        currency: 'EUR'
      }} textDecoration={"line-through"} decimal={2} />}
        {props.price && <GermanNumberText fontSize={{
        base: "3xl",
        md: "4xl"
      }} value={props.price} lineHeight={"28px"} content="" options={{
        style: 'currency',
        currency: 'EUR'
      }} textColor={"green.400"} fontWeight={"bold"} decimal={2} bgImage={"linear-gradient(45deg, #f72461, #c914ba)"} bgClip={"text"} />}
        {(props?.discount && props.discount > 0 || discount > 0) && <GermanNumberText fontSize={{
        base: "md",
        md: "xl"
      }} lineHeight={"2"} value={props.discount ? props.discount : discount} content="% Rabatt" textColor={"grey.400"} fontWeight={"bold"} decimal={0} contentLeft="-" options={{
        style: 'decimal'
      }} />}
      </VStack>
    </>;
};
_c3 = DealPriceBigCenter;
var _c, _c2, _c3;
$RefreshReg$(_c, "DealPrice");
$RefreshReg$(_c2, "DealPriceBig");
$RefreshReg$(_c3, "DealPriceBigCenter");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;