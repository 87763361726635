// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/dealList/Author.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/dealList/Author.tsx");
  import.meta.hot.lastModified = "1708335860436.164";
}
// REMIX HMR END

import { Avatar, Button, HStack, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, Text, VStack } from "@chakra-ui/react";
export const BlogAuthorPopover = props => {
  const {
    deal
  } = props;
  return <Popover isLazy={true}>
      <PopoverTrigger>
        <Button variant="link">
          <BlogAuthor name={deal.editor_username} imageURL={deal.editor_profilePictureThumb || undefined} />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <BlogAuthorBig name={deal.editor_username} imageURL={deal.editor_profilePicture || undefined} />
        </PopoverBody>
      </PopoverContent>
    </Popover>;
};
_c = BlogAuthorPopover;
export const BlogAuthor = props => {
  return <HStack spacing="2" display="flex">
      <Avatar loading="lazy" size="xs" src={props.imageURL} name={props.name} />
      <Text fontSize={"sm"} fontWeight="medium" maxW={70} isTruncated={true}>
        {props.name}
      </Text>
    </HStack>;
};
_c2 = BlogAuthor;
export const BlogAuthorBig = props => {
  return <VStack spacing="2" display="flex">
      <Avatar loading="lazy" size="xl" src={props.imageURL} name={props.name} />
      <Text fontSize={"xl"} fontWeight="medium">
        {props.name}
      </Text>
    </VStack>;
};
_c3 = BlogAuthorBig;
var _c, _c2, _c3;
$RefreshReg$(_c, "BlogAuthorPopover");
$RefreshReg$(_c2, "BlogAuthor");
$RefreshReg$(_c3, "BlogAuthorBig");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;