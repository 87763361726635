// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/dealList/DealDate.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/dealList/DealDate.tsx");
  import.meta.hot.lastModified = "1708510956415.2344";
}
// REMIX HMR END

import { TimeIcon } from "@chakra-ui/icons";
import { HStack, Text } from "@chakra-ui/react";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
moment.tz.setDefault("Europe/Berlin");
moment.locale("de");
export const DealDate = props => {
  _s();
  const dealDate = props.date ? moment(props.date) : moment();
  const threeDaysAgo = moment().subtract(1, "days");
  const [dateString, setDateString] = useState(dealDate.format("DD.MM.yyyy HH:mm"));
  useEffect(() => {
    if (!dealDate.isBefore(threeDaysAgo)) {
      const dateStr = dealDate.fromNow();
      setDateString(dateStr);
    }
  }, []);
  return <HStack {...props}>
      <TimeIcon />
      <Text>{dateString}</Text>
    </HStack>;
};
_s(DealDate, "6hh6/2i4IezPhe6IyXt+Inp0VTM=");
_c = DealDate;
var _c;
$RefreshReg$(_c, "DealDate");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;